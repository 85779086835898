.content {
  h1 {
    margin: 1rem 0;
  }

  h2 {
    margin: 1.5rem 0 1rem;
    font-size: 1.35rem;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
      margin: 2rem 0 1rem;

      &.subtitle {
        margin: 1.25rem 0 1rem;
      }
    }

    & + h3 {
      font-weight: 300;
    }
  }

  h3 {
    font-size: 1.35rem;
    line-height: 1.4em;
    margin-bottom: 1.35em;
  }

  img {
    margin: 0.8rem 0 1.35rem;
  }

  &.without-banner {
    padding-top: 1rem;
  }

  blockquote {
    position: relative;
    font-size: 1.35rem;
    font-weight: 300;
    margin: 1em 0;
    padding: 0.5em 0.5em 0.5em 5%;
    color: tint-color($charcoal, 10%);

    p {
      font-size: 1.35rem;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-left: solid 3px $sky;
      content: "";
    }
  }

  &.has-sticky-cta {
    padding-bottom: 6rem;

    @include media-breakpoint-up(sm) {
      padding-bottom: 2rem;
    }
  }
}

h1.page-title {
  display: inline-block;
  margin-bottom: 0;
  padding: 0.3em 1.65rem;
  background-color: #fff;
  line-height: 1.2em;
  font-size: 2.15rem;
  text-align: center;
  max-width: 45rem;

  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}

.page-intro-content {
  background-color: transparent;
  margin-top: -4rem;
  position: relative;
  text-align: center;
}

.page-intro-content-inner {
  padding: 1.5rem 1.3rem;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 2rem;
  }

  h2.subtitle {
    margin-top: 0;
    line-height: 1.25em;
    word-wrap: break-word;
  }

  *:last-child {
    margin-bottom: 0;
  }

  .packages-page & {
    padding-top: 1rem;
  }
}

.body-content {
  position: relative;

  @include clearfix;

  > :first-child.title-top-border {
    margin-top: 2.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  h2:first-child {
    margin-top: 0;
  }

  ul {
    @include ul-chevron-bullets;

    display: flex;
    flex-direction: column;
  }

  h4 {
    font-size: 1.35rem;
    margin: 1.3em 0 0.5em;

    strong {
      font-weight: 400;
    }
  }

  .block-pull {
    + h4 {
      margin-top: 0.5rem;
    }
  }
}

.title-top-border {
  position: relative;
  padding-top: 1rem;

  &::before {
    content: "";
    height: 4px;
    background-color: $sky;
    width: 15rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.offset-anchor {
  position: relative;
  visibility: hidden;
  top: calc(#{-$fixed-header-height} - 2rem);
}

.enquiry-sent-content {
  margin: 2rem auto;
  padding: 2rem 1rem;

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.35rem;
  }
}

.ul-chevron-bullets {
  @include ul-chevron-bullets;
}

.packages-page {
  position: relative;

  h1.page-title {
    margin-top: 0;
  }

  .tabs {
    margin-bottom: 1rem;
  }
}

.cms-content-wrapper {
  margin-top: 2.5rem;

  .content-rich-text {
    margin-bottom: 1rem;
  }

  img {
    + p {
      margin-top: 1rem;
    }
  }
}

.block-pull {
  &:nth-of-type(odd) {
    @include media-breakpoint-up(lg) {
      float: right;
      margin-left: 1.5rem;
      width: 50%;
    }
  }

  &:nth-of-type(even) {
    @include media-breakpoint-up(lg) {
      float: left;
      margin-right: 1.5rem;
      width: 50%;
    }
  }
}

ul.brilliant-benefits-list {
  list-style: none;
  margin-bottom: 2.5rem;

  li {
    position: relative;
    padding: 1.25rem 0 1rem 4rem;

    @include media-breakpoint-up(md) {
      padding-top: 1.75rem;
    }

    &::before {
      visibility: hidden;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 3rem;
      border: none;
    }
  }
}

.google-map {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  border: solid 8px #efefef;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.free-form-content-block {
  text-align: center;

  &:empty,
  .editable:empty {
    display: none;
  }

  *:last-child {
    margin-bottom: 0;
  }

  & + .cards-section:first-of-type {
    margin-top: 3rem;
  }

  .cms-editor-active & {
    display: block;
    min-height: 30px;

    .editable:empty {
      display: block;
    }
  }
}

.reasons-to-visit {
  ul {
    @include media-breakpoint-up(md) {
      column-count: 2;
      column-gap: 2.5rem;

      li {
        break-inside: avoid-column;
      }
    }
  }

  h2 {
    font-size: 1.75rem;
    line-height: 1.25em;
  }
}

.page-overview-content {
  & + .cards-section:first-of-type {
    margin-top: 2rem;
  }
}

.terms-conditions-list {
  ol {
    counter-reset: item;
    list-style-type: none;

    li {
      position: relative;
      margin-bottom: 1rem;

      &::before {
        counter-increment: item;
        content: counters(item, ".") " ";
        position: absolute;
        left: -2.5rem;
      }
    }
  }

  > ol {
    padding-left: 1rem;

    > li {
      &::before {
        content: counters(item, ".") ". ";
      }

      strong {
        display: inline-block;
        margin-left: -0.25rem;
        margin-bottom: 0.65rem;
      }
    }
  }

  .lower-alpha {
    list-style: lower-alpha;
    margin-top: 0.5rem;

    li::before {
      content: initial;
    }
  }

  .lower-roman {
    list-style: lower-roman;
    margin-top: 0.5rem;

    li::before {
      content: initial;
    }
  }
}

.destinations-rich-content-container {
  padding: 2.5rem 0;

  h3 {
    font-size: 1.65rem;
    margin-bottom: 0.8em;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  img {
    margin-bottom: 1rem;
    margin-top: 0.5rem;

    @include media-breakpoint-up(md) {
      float: left;
      max-width: 45%;
      margin-right: 1.35rem;
    }
  }

  &.even {
    color: #fff;

    @include media-breakpoint-up(md) {
      img {
        float: right;
        margin-left: 1.35rem;
      }
    }
  }

  &:last-of-type {
    margin-bottom: -3rem;
  }
}
