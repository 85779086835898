@each $name, $unit in $spacers {
  // Vertical dividers
  .divider-y-#{$name} {
    > * + * {
      padding-top: $unit;
      border-top: 1px solid $border-light-grey;
      margin-top: $unit;
    }
  }
  // Horizontal dividers
  .divider-x-#{$name} {
    > * + * {
      padding-left: $unit;
      border-left: 1px solid $border-light-grey;
      margin-left: $unit;
    }
  }
}
