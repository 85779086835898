$accordion-padding: 1rem 1.25rem;
$accordion-border: 1px solid rgba(0, 0, 0, 0.125);

.accordion {
  border: $accordion-border;

  & + .accordion {
    margin-top: 0.5rem;
  }
}

.accordion__header {
  padding: $accordion-padding;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  &::after {
    font: var(--fa-font-solid);
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    content: "\f077";
  }

  &.collapsed::after {
    content: "\f078";
  }
}

.accordion__body {
  border-top: $accordion-border;
  padding: $accordion-padding;
}
