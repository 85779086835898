.list--spaced {
  li + li {
    margin-top: 0.5rem;
  }
}

.list--icon {
  @include list-unstyled;

  li {
    padding-left: 1rem;
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      color: $sky;
      font: var(--fa-font-solid);
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-weight: 900;
    }
  }
}

.list--chevron {
  li {
    &::before {
      content: "\F054";
    }
  }
}
