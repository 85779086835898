.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #fff;
  transition: background-color 0.25s ease;

  &:not(.transparent-header) {
    box-shadow: 0 1px 0 rgba(#333, 0.15);
  }

  &.transparent-header {
    box-shadow: none;
    background-color: transparent;
  }

  > .container {
    @include media-breakpoint-between(xs, lg) {
      width: 100%;
      max-width: none;
    }
  }
}

.nav-wrapper {
  padding: 0.55rem 0;
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 0.95rem;

    .nav-item {
      padding: 0 0.85rem;
    }
  }
}

.nav-brand-toggler-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
}

.nav-brand {
  padding: 1rem 0;

  .svg-inline-logo {
    width: 160px;
    transition: all 0.15s ease;
  }

  .transparent-header & {
    .svg-inline-logo {
      width: 200px;

      .logo-fill {
        fill: #fff;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }
}

.header-stg-logo-link {
  width: 116px;
  height: 35px;
  flex-shrink: 0;
  background-image: url("../images/stg-logo-black.svg");
  opacity: 0.4;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  .transparent-header & {
    background-image: url("../images/stg-logo-white.svg");
    opacity: 1;
  }
}

.nav-ul-wrapper {
  display: block;
  width: 100%;
  position: relative;

  @include media-breakpoint-between(xs, md) {
    top: 5px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-self: center;
    justify-content: center;
    padding-top: 0;
  }
}

.nav-ul {
  display: block;
  padding: 0.5rem 0;
  margin: 0 -1rem;
  line-height: 1em;
  transition: height 0.15s ease;
  overflow: hidden;

  @include media-breakpoint-between(xs, lg) {
    background-color: #fff;

    &:not(.active) {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    margin: 0;
    padding: 0;
  }
}

.nav-item {
  display: block;
  list-style: none;
  padding: 0;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }

  &.active {
    .nav-link::before {
      transform: scaleX(1);
    }

    .nav-link {
      &::after {
        transform: rotate(180deg);
        top: 0;
      }
    }
  }

  .transparent-header & {
    background-color: #fff;

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }
  }
}

.nav-link {
  display: flex;
  color: $nav-text-colour;
  padding: 0.55rem 1rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 1.2rem 0;

    &::before {
      content: "";
      display: inline-block;
      height: 2px;
      width: 100%;
      background-color: $sky;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: transform 0.15s ease-out;
    }

    &:hover::before,
    &.active::before {
      transform: scaleX(1);
    }

    .transparent-header & {
      color: #fff;

      &::before {
        background-color: #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  &:hover {
    color: shade-color($nav-text-colour, 12.5%);
  }

  @include media-breakpoint-between(xs, md) {
    .nav-item.active &,
    &:hover {
      background-color: shade-color(#fff, 3.5%);
    }
  }

  .has-subnav & {
    &::after {
      font: var(--fa-font-solid);
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f078"; // chevron-down icon
      border: none;
      font-size: 0.75em;
      position: relative;
      top: 1px;
      margin-left: 6px;
    }
  }
}

.nav-subnav {
  display: none;
  background: #fff;
  padding: 5px 10px;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 100%;
    width: auto;
    padding: 10px;
    border: solid 1px $border-light-grey;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }

  .has-subnav.active & {
    display: block;
  }

  .nav-subnav-link {
    display: block;
    text-decoration: none;
    padding: 0.4rem 0.35rem 0.4rem 0.75rem;
    color: $nav-text-colour;
    font-size: 0.925rem;

    @include media-breakpoint-up(lg) {
      padding: 0.4rem 0.35rem;

      &::before {
        margin-right: 6px;
        color: $lagoon;
        font: var(--fa-font-solid);
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-size: 0.9em;
        content: "\f054"; // chevron-right icon
      }
    }

    @include hover {
      color: shade-color($nav-text-colour, 12.5%);
    }
  }
}

.nav-megamenu {
  display: none;
  list-style-type: none;
  background: #fff;
  padding: 5px 10px;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    column-count: 3;
    position: absolute;
    top: 100%;
    left: auto;
    width: auto;
    padding: 1.5rem 0.85rem 1rem;
    border: solid 1px $border-light-grey;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }

  .has-subnav.active & {
    display: block;
  }

  .nav-megamenu-li {
    break-inside: avoid;
  }

  .nav-megamenu-section {
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin: 0 1rem 1rem;
    }
  }

  .nav-megamenu-links {
    display: none;
    padding-left: 1.5rem;

    @include media-breakpoint-up(lg) {
      display: block;
      padding: 0;
    }
  }

  .nav-megamenu-section-name {
    display: block;
    text-decoration: none;
    padding: 0.4rem 0.35rem 0.4rem 0.75rem;
    color: #666;
    font-size: 0.925rem;

    @include media-breakpoint-down(lg) {
      &.active + .nav-megamenu-links {
        display: block;
      }

      &::after {
        font: var(--fa-font-solid);
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\f078"; // chevron-down icon
        font-size: 0.75em;
        position: relative;
        top: 0;
        margin-left: 6px;
      }

      &.active {
        &::after {
          content: "\f077"; // chevron-up icon
        }
      }
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0.5rem;
      padding: 0;
      text-transform: uppercase;
      color: $nav-text-colour;
      font-weight: bolder;

      @include hover {
        color: shade-color($nav-text-colour, 12.5%);
      }

      &::before {
        font: var(--fa-font-solid);
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        margin-right: 6px;
        font-size: 0.9em;
        color: $lagoon;
        content: "\f054"; // chevron-right icon
      }
    }
  }

  .nav-megamenu-link {
    display: block;
    text-decoration: none;
    padding: 0.25rem 0;
    color: $nav-text-colour;
    font-size: 0.925rem;

    @include hover {
      color: shade-color($nav-text-colour, 12.5%);
    }

    @include media-breakpoint-up(lg) {
      &::before {
        margin-right: 6px;
        font: var(--fa-font-solid);
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-size: 0.9em;
        color: $lagoon;
        content: "\f054"; // chevron-right icon
      }
    }
  }
}

.nav-toggler {
  display: block;
  background: transparent;
  border: none;
  width: 26px;
  height: 22px;
  position: relative;
  transition: 0.1s ease-in-out;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #666;
    border-radius: 9px;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.1s ease-in-out;

    .transparent-header & {
      background-color: #fff;
    }

    // Nav toggler (Hamburger icon)
    &:nth-child(1) {
      top: 2px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }

  &.active {
    // Nav toggler (Hamburger icon) animated to close (X)
    span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }
}
