.newsletter-signup-wrapper {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: solid 1px #fff;
  color: #fff;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    margin-top: 0;
    border: none;
  }
}

.newsletter-signup-input-wrapper {
  padding: 0.5rem;
  width: 50%;
}

.newsletter-title {
  display: block;
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 0.75rem;
  line-height: 1em;
  font-family: $secondary-font;

  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }
}

.newsletter-text {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.newsletter-signup__form {
  .fui-page,
  .fui-page-container {
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
  }

  .fui-row {
    flex: 1 1 100%;
  }

  .fui-btn-container {
    @include media-breakpoint-down(md) {
      flex: 1 1 100%;
    }

    display: flex;
  }

  input,
  select {
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    border-bottom: solid 1px shade-color($border-light-grey, 25%);
    transition: all 0.2s ease;
    border-radius: 0;
    padding: 0;
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    width: 100%;

    &:focus {
      border-bottom-color: #fff;
    }

    &::placeholder {
      color: shade-color(#fff, 50%);
    }
  }

  select {
    background-position-x: right;

    @extend .form-select, .form-select-white;

    option {
      color: #000;
    }
  }

  button {
    @extend .btn;

    font-size: 0.875rem;
    background-color: $footer-btn-colour;
    border-color: $footer-btn-colour;
    color: #fff;
    display: block;
    width: 100%;

    @include hover {
      background-color: shade-color($footer-btn-colour, 10%);
      border-color: shade-color($footer-btn-colour, 10%);
      color: #fff;
    }
  }
}
