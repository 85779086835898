.preview-tile {
  position: relative;
  background-size: cover;
  background-position: center;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease;
    z-index: 1;
    content: "";
  }

  @include hover {
    &::before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .preview-tile-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem;
    color: #fff;
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.15em;
    z-index: 2;

    @include text-stroke;

    @include media-breakpoint-up(xl) {
      font-size: 1.9rem;
    }

    @include hover {
      text-decoration: none;
    }

    &.responsive-square {
      &::after {
        padding-bottom: 66.6%;
      }
    }
  }
}
