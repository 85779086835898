.footer-wrapper {
  margin-top: auto;
}

.footer {
  padding: 1.75rem 0 1.5rem;
  color: #ccc;
  background-color: #414042;

  a {
    color: #ccc;
  }
}

.footer-nav-link {
  color: #fff;
  display: block;
  margin-bottom: 0.15rem;
  font-size: 0.925rem;
}

.footer-social-links {
  margin: 2rem 0 0;
  padding-top: 2rem;
  border-top: 1px solid #fff;
  font-size: 1.5rem;

  a {
    color: #fff;
    transition: opacity 0.15s ease;

    @include hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }

  @include media-breakpoint-up(md) {
    border-top: none;
    padding-top: 0;
    margin: 0;
  }
}

.footer-phone-number,
.footer-social-hashtag {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1em;
  font-family: $secondary-font;
  color: #fff;
  display: block;

  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }
}

.footer-phone-number {
  margin-bottom: 1.5rem;

  span {
    margin-right: 0.25rem;
  }
}

.footer-social-hashtag {
  margin-bottom: 0.75rem;
}
