.gift-cards {
  .card-footer {
    height: 3.5rem;
    padding: 0.5rem 1rem;
  }

  .page-item {
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .page-link {
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    margin: 0 0.25rem;
    align-items: center;
    justify-content: center;
    border-radius: 1.25rem !important;
    color: $black;
  }
}

.gift-cards__select {
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  width: 18.5rem;
}
