/* Style overrides when printing */

@media print {
  .nav-toggler,
  .footer-wrapper,
  .print-btn {
    display: none !important;
  }

  .homepage-carousel .carousel,
  .hero-banner {
    max-height: 400px !important;
  }

  .header {
    position: absolute;
  }

  .block-pull {
    float: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    page-break-before: always;
    page-break-inside: avoid;
  }

  img {
    page-break-inside: avoid;
  }
}
