.card-horizontal {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    gap: $grid-gutter-width;
    flex-direction: row;
    align-items: center;
    padding: $grid-gutter-width;
  }
}

.card-horizontal__image-wrapper {
  position: relative;

  @include media-breakpoint-up(md) {
    flex: 1 1 40%;
  }
}

.card-badge-text {
  position: absolute;
  right: 0;
  top: 0.85rem;
  font-size: $badge-font-size;
  color: white;
  background: $sunset;
  padding: 0.25em 0.65em 0.45em;
  display: block;
  font-family: $secondary-font;
}

.card-horizontal__content-wrapper {
  padding: $grid-gutter-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 auto;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    padding: 0;
    justify-content: center;
    flex: 1 1 60%;
  }
}
