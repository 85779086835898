.partner-brands__logo {
  display: inline-block;
  width: 7.5rem;
  margin: 1rem;
}

.footer--sky {
  background-color: $sky;
  color: $white;
}

.tabbed-nav {
  .nav-item {
    padding: 0;
  }

  .nav-link {
    color: $white;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    border-bottom: 3px solid rgba($light-grey, 0.5);

    &.active {
      border-bottom: 3px solid rgba($light-grey, 1);
    }

    &:hover {
      background-color: transparent;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 0.5rem;
      font-size: 1rem;
    }
  }
}

.tabbed-nav--blue {
  .nav-item {
    padding: 0;
  }

  .nav-link {
    color: $charcoal;
    border-bottom: 3px solid rgba($primary, 0.5);

    &.active {
      border-bottom: 3px solid rgba($primary, 1);
    }
  }
}

.number-in-circle {
  max-width: 50rem;
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    counter-increment: step-counter;

    &::before {
      content: counter(step-counter);
      margin-right: 0.5rem;
      flex: 0 0 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $black;
      border-radius: 1rem;
    }
  }
}

.number-in-circle--white {
  li {
    &::before {
      border-color: $white;
    }
  }
}
