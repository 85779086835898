$primary-font: "Roboto", sans-serif;
$secondary-font: "co-headline", sans-serif;

$gold: #ffd200;
$sunset: #f99d1c;
$outback: #b16913;
$red_ochre: #a22d16;
$orchid: #884f74;
$charcoal: #414042;
$ocean: #3d5e79;
$sky: #008fd5;
$surf: #58abda;
$lagoon: #439bae;
$forest: #54985c;
$eucalypt: #6f845d;

$nav-text-colour: #666;
$border-light-grey: #eee;
$footer-btn-colour: #888;

$divider: #e0e0e0;
$light-grey: #d9d9d9;

$fixed-header-height: 82px;

$badge-font-size: 0.9325rem;

// Bootstrap overrides
$enable-shadows: true;

$primary: $sky;

$custom-colors: (
  "primary": $sky,
  "gold": $gold,
  "sunset": $sunset,
  "outback": $outback,
  "red_ochre": $red_ochre,
  "orchid": $orchid,
  "charcoal": $charcoal,
  "ocean": $ocean,
  "sky": $sky,
  "surf": $surf,
  "lagoon": $lagoon,
  "forest": $forest,
  "eucalypt": $eucalypt,
);

$list-group-active-color: unset;
$list-group-active-bg: unset;
$list-group-border-color: rgba(#000, 0.125);
$list-group-active-border-color: $list-group-border-color;

$spacer: 1rem;

$custom-spacers: (
  6: (
    $spacer * 4,
  ),
  7: (
    $spacer * 5,
  ),
);

// Alerts

$alert-border-width: 0;
$alert-margin-bottom: 0;

// Forms

$legend-font-size: 1rem;
$legend-font-weight: 700;
