@use "sass:map";

.btn {
  box-shadow: none;

  .fa-external-link {
    font-size: 0.7em;
    position: relative;
    top: -0.25em;
    margin-left: 2px;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-form-submit {
  width: 100%;

  @include fa-icon-before("\f054", false, 700);

  padding: 0.65em 1.15em;

  @include media-breakpoint-up(md) {
    width: 160px;
  }

  &::before {
    position: relative;
    top: 1px;
  }
}

.chevron-text-cta {
  display: inline-block;
  font-family: $secondary-font;
  font-weight: 500;
  color: $sky;
  transition: all 0.15s ease;
  padding: 0.35em 0.85em;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;

  @include fa-icon-before("\f138", $sky, 700);

  &::before {
    position: relative;
    top: 2px;
    margin-right: 0.5rem;
    transition: color 0.15s ease;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: $sky;
    bottom: 0;
    left: 0;
    top: 0;
    right: 100%;
    transition: right 0.15s ease-out;
    z-index: -1;
  }

  @include hover {
    color: #fff;
    text-decoration: none;

    &::before {
      color: #fff;
    }

    &::after {
      right: 0;
    }
  }

  &:active {
    background-color: shade-color($sky, 2%);
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.25);
  }

  .homepage-content & {
    margin: 0.5rem 0 2rem;
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }
  }
}

.btn-primary {
  color: white;

  &:hover,
  &:active,
  &:focus {
    color: white;
  }
}

.btn-white {
  background-color: $white;
  font-family: $secondary-font;
  color: $sky;
}

.btn-white--inverse {
  &:hover,
  &:active,
  &:focus {
    color: $white !important;
    background-color: $sky;
    border-color: $white;
  }
}

.print-icon-wrapper {
  .breadcrumbs ~ & {
    margin: -0.5rem 0 2rem;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.print-btn {
  @include fa-icon-before("\f02f", $charcoal); // print icon

  color: $charcoal;
  cursor: pointer;
  font-size: 0.9375rem;

  @include hover {
    color: $sky;

    &::before {
      color: $sky;
    }
  }
}

.btn-sticky-join-cta {
  position: sticky;
  right: 0;
  bottom: 20px;
  margin-top: -2rem;
  background-color: tint-color($forest, 5%);
  border-color: $forest;
  color: #fff;
  border-radius: 0;
  z-index: map.get($zindex, sticky-cta);
  font-family: $secondary-font;
  line-height: 1.15em;
  padding: 0.75rem;
  float: right;
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 290px;
  }

  @media screen and (min-width: 769px) {
    width: 290px;
    transform: translate3d(30px, 0, 0);
  }

  @include media-breakpoint-up(lg) {
    transform: translate3d(60px, 0, 0);
  }

  @include media-breakpoint-up(xl) {
    transform: translate3d(50%, 0, 0);
  }

  span {
    display: block;
  }

  small {
    display: inline-block;
    font-family: $primary-font;
    font-weight: 300;
    line-height: 1.25em;
  }

  &:hover {
    color: #fff;
    background-color: $forest;
  }
}

.package-buttons-wrapper {
  @include media-breakpoint-up(md) {
    div:not(:first-of-type) {
      border-left: solid 1px #dedede;
    }
  }
}

.package-cta-btn {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.15em;
  text-transform: uppercase;

  &.book-btn {
    background-color: $forest;
    border-color: $forest;

    @include hover {
      background-color: shade-color($forest, 5%);
      border-color: shade-color($forest, 5%);
    }
  }

  &.book-external-btn {
    background-color: $charcoal;
    border-color: $charcoal;

    @include hover {
      background-color: shade-color($charcoal, 5%);
      border-color: shade-color($charcoal, 5%);
    }
  }

  &.other-deals {
    color: #fff;
    background-color: $sunset;
    border-color: $sunset;

    @include hover {
      background-color: shade-color($sunset, 5%);
      border-color: shade-color($sunset, 5%);
    }
  }
}
