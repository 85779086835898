.packages-card {
  &:empty {
    display: none;
  }

  &[data-departing-location] {
    display: none;
  }

  &[data-departing-location=""],
  &[data-departing-location="sydney"] {
    display: flex;
  }

  .card-heading-wrapper {
    @include media-breakpoint-down(md) {
      bottom: auto;
      top: -1.85rem;
    }

    @include media-breakpoint-up(sm) {
      position: relative;
      bottom: auto;
      left: auto;
      top: auto;
    }

    @include media-breakpoint-up(md) {
      padding-right: 100px;
    }

    .card-heading {
      @include media-breakpoint-up(sm) {
        color: $charcoal;
        font-size: 1.5rem;

        span {
          background-color: initial;
          padding: initial;
          line-height: 1.25em;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .packages-title-alt {
      top: auto;
      bottom: -0.5rem;
    }
  }

  .card-heading {
    font-family: $secondary-font;
    margin: 0;

    @include media-breakpoint-up(sm) {
      margin: -0.75rem 0 0.75rem;
    }
  }

  &-short-desc {
    color: $charcoal;
  }

  .stretched_link {
    @include hover {
      text-decoration: none;
    }
  }

  .packages-card-img {
    display: block;
    width: 100%;
    background-position: center;
    background-size: cover;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  .package-price {
    margin: 0.35rem 0 1rem;
    font-family: $secondary-font;
    font-size: 1.15rem;
    color: $charcoal;

    a:hover & {
      color: $charcoal;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      right: -0.75rem;
      top: 0.85rem;
      margin: 0;
      padding: 0.25em 0.65em 0.45em;
      color: #fff;
      background: $sky;
      font-size: $badge-font-size;
    }
  }

  .package-image-text {
    position: absolute;
    left: -0.3rem;
    top: 0.85rem;
    font-size: $badge-font-size;
    color: white;
    background: $sunset;
    padding: 0.25em 0.65em 0.45em;
    display: block;
    font-family: $secondary-font;

    @include media-breakpoint-up(md) {
      left: -0.75rem;
    }
  }
}
