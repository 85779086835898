* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $primary-font;
  overflow-y: scroll;
  color: $charcoal;
  padding-top: $fixed-header-height;

  &.page-has-transparent-header {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 33.3vh;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
      z-index: 2;
      pointer-events: none;
      content: "";
    }
  }
}

img {
  width: 100%;
  height: auto;
}

.responsive-square {
  display: block;
  width: 100%;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.list-unstyled {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.w-auto {
  width: auto;
}

.responsive-video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; // 16:9
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.grecaptcha-badge {
  // Hide the badge as it conflicts with other marketing widgets and LiveChat etc.
  // Instead we're following the requirements stated here:
  // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
  visibility: hidden;
  pointer-events: none;
}

.line-clamp-2 {
  @include line-clamp(2);
}

.line-clamp-3 {
  @include line-clamp(3);
}

.line-clamp-4 {
  @include line-clamp(4);
}
