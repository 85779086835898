.filter-wrapper {
  margin: 3rem 0 -3rem;
  user-select: none;
}

.departing-selector {
  position: relative;
  display: inline-block;
}

.filter-option,
.reset-filter {
  cursor: pointer;
  text-transform: capitalize;
  color: $nav-text-colour;

  @include hover {
    color: shade-color($nav-text-colour, 12.5%);
    text-decoration: none;
  }

  &:empty {
    display: none;
  }

  &::before {
    position: relative;
    font: var(--fa-font-solid);
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 6px;
    color: #439bae;
    font-size: 0.8em;
    content: "\f054";
  }
}

.selected-filter-text {
  text-transform: capitalize;
}

.filter-selection-text {
  font-size: 1.2rem;
  font-weight: 300;
}

.selected-filter {
  display: inline-block;
  position: relative;
  line-height: 1.1em;
  font-weight: 500;
  font-family: $secondary-font;
  font-size: 1.25rem;
  color: #333;
  border-bottom: solid 2px #333;
  cursor: pointer;

  .fas {
    position: absolute;
    right: -1.25rem;
    top: 50%;
    transform: translateY(-35%);
  }
}

.departing-dropdown,
.offers-portal__state-dropdown {
  display: none;
  position: absolute;
  top: 120%;
  left: 0;
  padding: 0.75rem 1rem;
  width: auto;
  min-width: 180px;
  text-align: left;
  z-index: 2;
  background-color: #fcfcfc;
  border: solid 1px #ddd;
  box-shadow: 0 0.35rem 0.5rem rgba(0, 0, 0, 0.15);
}

.offers-portal__state-selector {
  display: inline-block;
  position: relative;
  line-height: 1.5rem;
  font-weight: 500;
  font-family: $secondary-font;
  font-size: 1.25rem;
  color: $charcoal;
  border-bottom: solid 2px $charcoal;
  cursor: pointer;

  i {
    font-size: 1rem;
    position: absolute;
    right: -0.85rem;
    top: 50%;
    transform: translateY(-35%);
  }
}
