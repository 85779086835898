.packages-page.packages-page-legacy {
  .btn-cta {
    text-align: center;
    z-index: 3;
    background-color: $sky;
    color: #fff;
    font-size: 1rem;
    padding: 0.65em 1.15em;

    @include fa-icon-before("\f054", #fff, 700);

    @include hover {
      color: #fff;
      background-color: shade-color($sky, 5%);
    }

    &::before {
      position: relative;
      top: 1px;
      margin-right: 6px;
    }
  }

  .btn-cta-sticky {
    transition: opacity 0.15s ease;
    opacity: 0;
    position: fixed;
    bottom: 1rem;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: initial;
    }

    &.pinned {
      position: absolute;
      bottom: 0;
    }
  }

  .featured-promotion-cta {
    &.circle-brand-device {
      border-color: #fff;
      position: absolute;
      bottom: 0.65rem;
      right: 0.65rem;
      pointer-events: none;

      span {
        color: #fff;
        line-height: 1.1em;
        font-size: 0.9rem;
      }

      @include media-breakpoint-up(sm) {
        bottom: 2rem;
        right: 2rem;
        padding: 1.25rem;

        span {
          font-size: 1.35rem;
        }
      }
    }

    &.circle-brand-device-solid {
      border-color: transparent;
    }
  }

  .circle-brand-device {
    display: inline-block;
    position: relative;
    padding: 1rem;
    text-decoration: none;
    z-index: 2;
    background-color: transparent;
    border-radius: 100%;
    border: solid 3px $sky;
    text-align: center;
    font-family: $secondary-font;
    outline: none;
    font-size: 0;

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.25rem;
      line-height: 1.25em;
      color: $sky;
    }

    &.circle-brand-device-solid {
      background-color: $sky;

      span {
        color: #fff;
      }

      &::after {
        display: block;
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 50%;
        border: solid 2px #fff;
        padding-top: 8px;
        vertical-align: middle;
        content: "";
      }
    }

    &::before {
      display: inline-block;
      padding-top: 100%;
      vertical-align: middle;
      content: "";
    }
  }

  .inclusions {
    margin-bottom: 1.5rem;

    h4 {
      margin-top: 0;
    }

    .inclusions-list {
      font-size: 0.95rem;
      margin-bottom: 0;
      column-gap: 1.5rem;
      display: list-item;

      li {
        break-inside: avoid-column;
      }

      @include media-breakpoint-up(md) {
        column-count: 2;
      }

      &.one-col {
        column-count: 1;
      }
    }
  }

  .enquiry-form-wrapper {
    padding-top: 2rem;
    clear: both;

    .form-intro,
    .form-intro p {
      font-size: 1rem;
    }
  }

  .inclusions-itinerary-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .itinerary-pdf-download {
      margin-bottom: 0.5rem;
    }
  }

  .from-price-cta-wrapper {
    display: flex;
    transition: opacity 0.15s ease;

    @include media-breakpoint-down(md) {
      position: initial;
      z-index: 3;
      justify-content: space-between;

      &.active {
        opacity: 1;
        position: fixed;
        bottom: 1rem;
        left: 0;
        right: 0;
      }

      .circle-brand-device {
        max-width: 120px;
        max-height: 120px;
      }

      @include media-breakpoint-up(md) {
        .btn-cta {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: column;
      position: initial;
      padding-top: 1rem;
      padding-bottom: 0;
      order: 2;

      .btn-cta {
        margin-top: 1.25rem;
        align-self: flex-end;
        width: 160px;
      }
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 1.5rem;

      .circle-brand-device {
        span {
          font-size: 1rem;
        }
      }
    }

    .circle-brand-device {
      @include media-breakpoint-up(sm) {
        max-width: 160px;
        max-height: 160px;
      }

      @include media-breakpoint-up(md) {
        align-self: flex-end;
      }
    }

    .btn-cta-wrapper {
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        padding-left: 1rem;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
      }
    }

    @include media-breakpoint-down(md) {
      .btn-cta-sticky {
        width: calc(100% - 2rem);
        left: 1rem;
        right: 1rem;

        &.pinned {
          width: 100%;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .itinerary-pdf-download {
    color: $sky;
    font-family: $secondary-font;
    font-size: 1.15rem;

    @include fa-icon-before("\f33d", $sky, $icon-weight: 700);

    small {
      font-size: 0.7em;
    }

    &::before {
      margin-right: 2px;
    }

    @include hover {
      text-decoration: none;
      color: shade-color($sky, 10%);

      &::before {
        color: shade-color($sky, 10%);
      }
    }
  }

  .block-pull {
    &:nth-of-type(odd) {
      @include media-breakpoint-up(lg) {
        float: right;
        margin-left: 1.5rem;
        width: 50%;
        margin-right: -8%;
      }

      @include media-breakpoint-up(xl) {
        width: 66%;
        margin-right: -16%;
      }
    }

    &:nth-of-type(even) {
      @include media-breakpoint-up(lg) {
        float: left;
        margin-right: 1.5rem;
        width: 50%;
        margin-left: -8%;
      }

      @include media-breakpoint-up(xl) {
        width: 66%;
        margin-left: -16%;
      }
    }
  }
}
