@use "sass:math";

.card {
  @extend .shadow;

  border-radius: 0;
  border: none;
  text-align: left;
}

.card-animate {
  transform: translateY(0);
  transition: all 0.15s ease;

  @include hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important; // sad!
    transform: translateY(-1px);
  }
}

.cards-section {
  position: relative;
  padding: 2rem 15px 3rem;
  margin-top: 6rem;
  margin-left: -15px;
  margin-right: -15px;

  @include media-breakpoint-up(lg) {
    overflow-x: initial;
  }

  &:first-of-type {
    margin-top: 3.5rem;

    @include media-breakpoint-up(lg) {
      margin-top: 4.5rem;
    }
  }

  &:not(:first-of-type) {
    &::before {
      content: "";
      height: 4px;
      background-color: $sky;
      width: 40px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .anchor {
    display: block;
    position: relative;
    top: -7.5rem;
    visibility: hidden;
  }

  h3 {
    color: $charcoal;
    text-align: center;
    font-size: 1.85rem;
    font-weight: 400;
    font-family: $secondary-font;
    margin: 0 0 1em;
    text-shadow:
      rgb(255, 255, 255) 3px 0 0,
      rgb(255, 255, 255) 2.83487px 0.981584px 0,
      rgb(255, 255, 255) 2.35766px 1.85511px 0,
      rgb(255, 255, 255) 1.62091px 2.52441px 0,
      rgb(255, 255, 255) 0.705713px 2.91581px 0,
      rgb(255, 255, 255) -0.287171px 2.98622px 0,
      rgb(255, 255, 255) -1.24844px 2.72789px 0,
      rgb(255, 255, 255) -2.07227px 2.16926px 0,
      rgb(255, 255, 255) -2.66798px 1.37182px 0,
      rgb(255, 255, 255) -2.96998px 0.42336px 0,
      rgb(255, 255, 255) -2.94502px -0.571704px 0,
      rgb(255, 255, 255) -2.59586px -1.50383px 0,
      rgb(255, 255, 255) -1.96093px -2.27041px 0,
      rgb(255, 255, 255) -1.11013px -2.78704px 0,
      rgb(255, 255, 255) -0.137119px -2.99686px 0,
      rgb(255, 255, 255) 0.850987px -2.87677px 0,
      rgb(255, 255, 255) 1.74541px -2.43999px 0,
      rgb(255, 255, 255) 2.44769px -1.73459px 0,
      rgb(255, 255, 255) 2.88051px -0.838247px 0;
    position: relative;
    z-index: 2;
  }

  & + .cards-section {
    margin-top: 2rem;
  }

  .row {
    justify-content: center;
  }
}

.cards-section-watermark {
  position: absolute;
  transform: translate(-1.5rem, -2.5rem);
  opacity: 0.03;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
  width: 800px;
  height: 230px;
  max-width: 90%;

  @include media-breakpoint-up(md) {
    transform: translate(-6rem, -3.5rem);
  }

  &.family-watermark {
    background-image: url("../images/family-watermark.png");
  }

  &.destinations-watermark {
    background-image: url("../images/destinations-watermark.png");
  }

  &.experiences-watermark {
    background-image: url("../images/experiences-watermark.png");
  }

  &.holiday-watermark {
    background-image: url("../images/holiday-watermark.png");
  }
}

.card-body {
  padding: 1rem;

  .card-title {
    font-size: 1.2rem;
    line-height: 1.35em;
  }

  .card-text {
    p {
      font-size: 0.9375rem;
      line-height: 1.55em;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.featured-promotion-card {
  margin: 5rem 0 -2rem;
  cursor: pointer;

  .btn {
    position: absolute;
    font-size: 1.15rem;
    right: 2rem;
    bottom: 2rem;
    padding: 0.5rem 0.85rem;
    background-color: #fff;
    font-weight: 500;

    @include hover {
      background-color: $sky;
      color: #fff;
    }
  }

  img {
    margin: 0;
  }
}

img.card-img {
  margin-bottom: 0;
  border-radius: 0;
}

.card-img-top {
  & + .card-body {
    padding-top: 0;
  }
}

.card-top {
  position: relative;

  &.responsive-square {
    background-size: cover;
    background-position: center;
  }

  .tile-badge {
    position: absolute;
    max-width: 55px;
    top: 18px;
    right: 18px;
    margin: 0;
  }
}

.card-heading-wrapper {
  position: absolute;
  bottom: 0;
  left: -0.25rem;
  text-align: left;
}

.card-heading {
  margin-bottom: 0;
  display: inline-block;
  padding: 0;
  color: #fff;
  font-size: 1.35rem;
  line-height: 0;

  span {
    background-color: $sky;
    display: inline-block;
    padding: 0.5em 0.75rem;
    line-height: 1.2em;
  }
}

.card-tagline {
  display: inline-block;
  background-color: tint-color($sky, 15%);
  line-height: 1.35rem;
  padding: 0.3rem 0.5rem;
  position: relative;
  color: #fff;
  margin: 3px 0 0 -1px;

  &:empty {
    display: none;
  }
}

.card-footer {
  border-top: none;
  background-color: initial;
  height: 2.5rem;
  padding: 0.75rem 1rem 2.5rem;

  .fa-external-link {
    font-size: 0.65em;
    margin-left: 4px;
    position: relative;
    top: -5px;
    color: $sky;
  }
}

.card-link-text-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  @include fa-icon-before("\f054", $sky, 700);
}

.card-link-text {
  display: inline-block;
  font-size: 1rem;
  color: $sky;
}

.card-tile-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.25s ease;
    content: "";
  }

  @include hover {
    &::before {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}

.landing-page-campaign-tiles {
  margin-top: 3rem;
  margin-bottom: -3rem;

  .body-content & {
    margin-bottom: 3rem;
  }

  &:empty {
    display: none;
  }

  .responsive-square {
    background-position: center;
  }

  img {
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: -2rem;

    > [class^="col"]:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
}

.cards-2-col {
  .col-md-4 {
    @include media-breakpoint-up(md) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

// Card wide
.card-wide {
  &:nth-child(2n) {
    .card-wide-image-wrapper {
      @include media-breakpoint-up(md) {
        order: 1;
      }
    }
  }

  & + .card-wide {
    margin-top: 3rem;
  }
}

.card-wide-image {
  padding-bottom: math.div(400, 700) * 100%; // Matches mobile image size
  background-size: cover;
  background-position: center;

  @include media-breakpoint-up(md) {
    padding-bottom: 0%;
    height: 100%;
  }
}

.card-wide-image-picture-wrapper {
  position: relative;

  picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
    }
  }
}

.card-wide-image-anchored-top {
  img {
    object-position: 50% 0%;
  }
}

.card-wide-content-wrapper {
  @include media-breakpoint-up(md) {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

.section-divider {
  position: relative;

  &::before {
    content: "";
    height: 4px;
    background-color: $sky;
    width: 40px;
    display: block;
    margin: 0 auto;
  }
}

.card--capped {
  border-top: 2px solid $sky;
}
