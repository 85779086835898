.homepage-about-stg {
  position: relative;
  margin-top: -4.25rem;
  padding: 1rem;
  text-align: center;
  background-color: #fff;

  @include media-breakpoint-up(lg) {
    padding: 1.5rem 2rem;
  }
}

h2.homepage-about-stg-heading {
  font-size: 1.15rem;
  margin: 1.35rem 0 0;
  line-height: 1.15em;
  color: #000;
  letter-spacing: 0.03em;

  @include brilliant-travels-block-font;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }

  .homepage-about-brilliant-inline {
    width: 110px;
    position: relative;
    margin: 0 0.2em;

    @include media-breakpoint-up(md) {
      width: 150px;
      top: -0.1em;
    }
  }
}

.homepage-about-stg-text {
  p {
    font-size: 1.2rem;
    line-height: 1.5em;
  }

  @include media-breakpoint-down(lg) {
    br {
      display: none;
    }
  }
}

.homepage-content {
  section {
    text-align: center;
  }
}

.homepage-brands-section {
  padding-left: 7.5%;
  padding-right: 7.5%;

  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.col-brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 85%;
  }
}

.homepage-promo-tiles {
  > [class*="col"] {
    margin-top: 2rem;
  }
}
