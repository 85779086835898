$fui-gap: 1rem;

.formie-layout--default-bt-template {
  // Layout

  .fui-i,
  .fui-page,
  .fui-page-container {
    display: flex;
    flex-direction: column;
    gap: $fui-gap;
  }

  .fui-row {
    gap: $fui-gap;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  // Inputs

  .fui-label {
    @extend .form-label;
  }

  .fui-input:not(.fui-radio-input):not(.fui-checkbox-input) {
    @extend .form-control;
  }

  .fui-select {
    @extend .form-select;

    overflow-x: hidden;
  }

  // Checks and radios

  .fui-radio,
  .fui-checkbox {
    @extend .form-check;
  }

  .fui-radio-input,
  .fui-checkbox-input {
    @extend .form-check-input;
  }

  .fui-radio-label,
  .fui-checkbox-label {
    @extend .form-check-label;
  }

  // Submit

  .fui-submit {
    @extend .btn, .btn-primary;
  }

  // Field errors

  .fui-error-message {
    @extend .form-error;
  }

  // Alerts

  .fui-alert {
    @extend .alert;
  }

  .fui-alert-success {
    @extend .alert-success;
  }

  .fui-alert-error {
    @extend .alert-danger;
  }
}
