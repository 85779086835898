.error-404-content {
  padding: 2rem 15px 0.5rem;

  h1 {
    font-size: 3.5rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }
  }

  h2 {
    font-size: 1.75rem;
    line-height: 1.2em;

    @include media-breakpoint-up(md) {
      font-size: 2.65rem;
    }
  }
}
