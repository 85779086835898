.list-group-item.active {
  &::before {
    content: "";
    background: $sky;
    width: 2px;
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
  }
}

.list-group-item + .list-group-item.active {
  margin-top: 0;
  border-top-width: 0;
}
