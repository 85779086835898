.hero-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  height: calc(75vh - #{$fixed-header-height});
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @include media-breakpoint-up(md) {
    height: calc(80vh - #{$fixed-header-height});
  }

  .page-has-transparent-header & {
    margin-top: -$fixed-header-height;
    height: 75vh;

    @include media-breakpoint-up(md) {
      height: 80vh;
    }
  }

  .content-page-banner & {
    margin-bottom: 2rem;

    &.has-colour-border {
      margin-bottom: calc(2rem + 40px);

      h1.page-title {
        transform: translateY(40px);
      }
    }
  }

  .hero-banner__background-image {
    @include full-wh-object(cover);
  }
}

.holiday-here-this-year-campaign-logo {
  display: none;

  @include media-breakpoint-up(md) {
    display: inline-block;
    float: right;
    width: 120px;
    margin-bottom: 80px;
    margin-right: -50px;
  }

  @include media-breakpoint-up(lg) {
    width: 150px;
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 80px;
  }
}
