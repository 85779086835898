.post-date {
  font-size: 1rem;
  color: tint-color(#212529, 50%);
  font-style: italic;
  margin-bottom: 2rem;
}

.post-tags {
  font-style: italic;
  margin: 2rem 0;
  font-weight: 700;
  font-size: 1rem;
}

.post-list {
  h5 {
    margin: 0;
  }

  .post-date {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }
}

.post-summary {
  margin: 1.5rem 0;
  position: relative;
  padding-bottom: 1.5rem;

  &::after {
    content: "";
    height: 4px;
    background-color: $sky;
    width: 5rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .post-date {
    font-size: 0.8rem;
    margin-bottom: 0.6rem;
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.25em;
  }

  .post-read-more {
    &::after {
      font: var(--fa-font-solid);
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f054"; // chevron-down icon
      border: none;
      font-size: 0.85em;
      position: relative;
      margin-left: 8px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
