h1,
h2,
h3,
h4 {
  font-family: $secondary-font;
  font-weight: 400;
}

a {
  text-decoration: none;
}

// Headings

@mixin heading-shared {
  font-family: $secondary-font;
  font-weight: 400;
  line-height: 1.4;
  transition: font-size 0.25s;
}

@mixin heading-one {
  @include heading-shared;

  font-size: 2rem;

  @include media-breakpoint-up(sm) {
    font-size: 2.75rem;
  }
}

@mixin heading-two {
  @include heading-shared;

  font-size: 1.5rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

@mixin heading-three {
  @include heading-shared;

  font-size: 1.4rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.6rem;
  }
}

@mixin heading-four {
  @include heading-shared;

  font-size: 1.3rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.45rem;
  }
}

@mixin heading-five {
  @include heading-shared;

  font-size: 1.2rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.3rem;
  }
}

@mixin heading-six {
  @include heading-shared;

  font-size: 1.1rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.15rem;
  }
}

.h1 {
  @include heading-one;
}

.h2 {
  @include heading-two;
}

.h3 {
  @include heading-three;
}

.h4 {
  @include heading-four;
}

.h5 {
  @include heading-five;
}

.h6 {
  @include heading-six;
}

.section-heading {
  border-left: 2px solid $sky;
  padding-left: 1rem;
}

//
// Small text
//

@mixin text-small {
  font-size: 0.9rem;
}

@mixin text-x-small {
  font-size: 0.8rem;
}

.text-small {
  @include text-small;
}

.text-x-small {
  @include text-x-small;
}
