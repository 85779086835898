.breadcrumbs {
  margin: 1.25rem 0 0;
  text-align: left;
  font-size: 0.85rem;
  color: #999;

  a {
    display: inline-block;
    position: relative;
    padding-right: 0.75rem;
    margin-right: 0.25rem;
    color: #777;

    &::after {
      position: absolute;
      right: 0;
      top: 6px;
      font: var(--fa-font-solid);
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-size: 0.8em;
      content: "\f054"; // chevron-right icon
    }
  }
}
