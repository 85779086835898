@mixin hover($active-class: "") {
  &:hover,
  &:focus,
  &:active {
    @content;
  }

  @if $active-class != "" {
    &#{$active-class} {
      @content;
    }
  }
}

@mixin brilliant-travels-block-font {
  font-family: co-headline, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
}

@mixin text-stroke {
  // subtle text stroke
  text-shadow:
    rgba(0, 0, 0, 0.15) 1px 0 0,
    rgba(0, 0, 0, 0.15) 0.540302px 0.841471px 0,
    rgba(0, 0, 0, 0.15) -0.416147px 0.909297px 0,
    rgba(0, 0, 0, 0.15) -0.989992px 0.14112px 0,
    rgba(0, 0, 0, 0.15) -0.653644px -0.756802px 0,
    rgba(0, 0, 0, 0.15) 0.283662px -0.958924px 0,
    rgba(0, 0, 0, 0.15) 0.96017px -0.279415px 0;
}

@mixin fa-icon-before($fa-icon-unicode, $colour, $icon-weight: 400) {
  &::before {
    display: inline-block;
    font: var(--fa-font-solid);
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    margin-right: 5px;
    font-size: 1.15em;

    @if $colour {
      color: $colour;
    }

    content: "#{$fa-icon-unicode}";

    @if $icon-weight {
      font-weight: $icon-weight;
    }
  }
}

@mixin ul-chevron-bullets {
  list-style: none;
  padding-left: 0;

  li {
    padding-left: 1.15rem;
    margin-bottom: 0.5rem;
    position: relative;

    @include fa-icon-before("\f054", $sky);

    &::before {
      position: absolute;
      top: 5px;
      left: 0;
      margin-right: 6px;
      font-size: 0.85em;
    }
  }
}

@mixin full-wh-object($object-fit: "") {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @if $object-fit != "" {
    object-fit: $object-fit;
  }
}

@mixin line-clamp($line-number: 1) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-number;
  overflow: hidden;
}
