.picture__wrapper {
  position: relative;
  background: $light;
  width: 100%;

  picture {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
