// This is a newer vewrsion of the .content class which is currently a bit of a mess

.rich-content {
  // Headings

  h1,
  .h1 {
    @include heading-one;
  }

  h2,
  .h2 {
    @include heading-two;
  }

  h3,
  .h3 {
    @include heading-three;
  }

  h4,
  .h4 {
    @include heading-four;
  }

  h5,
  .h5 {
    @include heading-five;
  }

  h6,
  .h6 {
    @include heading-six;
  }

  // Remove spacing from first and last elements

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.rich-content--default-spacing {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem;
  }

  p,
  ul,
  ol,
  .table-responsive {
    margin: 1rem 0;
  }

  img {
    margin: 1.5rem 0;
    max-width: 100%;
    height: auto;
  }
}

.rich-content--tight-spacing {
  & > * {
    margin: 0.75rem 0;
  }
}
