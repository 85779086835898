.carousel {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.homepage-carousel {
  position: relative;

  > .carousel {
    position: relative;
    background-size: cover;
    background-position: center;
    height: calc(75vh - #{$fixed-header-height});
    min-height: 400px;
    max-height: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #909090;

    @include media-breakpoint-up(md) {
      height: 85vh;
    }

    .page-has-transparent-header & {
      margin-top: -$fixed-header-height;
      height: 75vh;

      @include media-breakpoint-up(md) {
        height: 85vh;
      }
    }
  }

  .hero-banner__home-age-background-image {
    @include full-wh-object(cover);
  }
}

.carousel-content-wrapper {
  position: absolute;
  top: -1rem;
  left: 0;
  background-color: $sky;
  transform: rotate(7.5deg) translate3d(-10%, -10%, 0);
  width: 65%;

  @include media-breakpoint-up(sm) {
    width: 45%;
  }
}

.carousel-container {
  position: relative;
  margin: auto;
  background-size: cover;
  background-position: center;
  height: calc(75vh - #{$fixed-header-height});
  min-height: 400px;
  max-height: 700px;
}

.carousel-slide {
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  white-space: normal;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0 30px 0 #fff;
}

.carousel-controls-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 1140px;
  pointer-events: none;
}

.carousel-arrows {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  color: #fff;
  background: transparent;
  border: none;
  opacity: 0.85;
  transition: opacity 0.2s ease;
  font-size: 2.5rem;
  pointer-events: initial;

  i {
    pointer-events: none;
  }

  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }

  @include hover {
    opacity: 1;
    outline: none;
  }

  &.carousel-arrow-left {
    left: 0.5rem;

    @include media-breakpoint-up(md) {
      left: 1.5rem;
    }
  }

  &.carousel-arrow-right {
    right: 0.5rem;

    @include media-breakpoint-up(md) {
      right: 1.5rem;
    }
  }
}

.carousel-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5.5rem;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: initial;

  @include media-breakpoint-up(sm) {
    bottom: 6rem;
  }

  .carousel-dot {
    background: rgba(#fff, 0.6);
    border: none;
    width: 1.5rem;
    height: 0.4rem;
    margin: 0 3px;
    cursor: pointer;

    &.active {
      background: #fff;
    }
  }
}

.carousel-animate {
  animation-name: carousel-animate;
  animation-duration: 0.35s;

  &:not(.show) {
    opacity: 1;
  }
}

@keyframes carousel-animate {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
}

.slide-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  transform: rotate(-7.5deg) translate3d(10%, 10%, 0);
  padding: 3rem 2.25rem 3.5rem 1.75rem;
  max-width: 90%;

  @include media-breakpoint-up(md) {
    padding: 3rem 3rem 3rem 2rem;
  }
}

h2.carousel-title {
  margin-bottom: 0.5rem;
  text-align: left;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.25em;

  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }

  strong {
    padding: 0.2em 0;
  }
}

.carousel-text {
  max-width: 95%;
  text-align: left;
  color: #fff;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.15rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
  }
}

.carousel-cta {
  align-self: flex-end;
  margin: 1.5rem 0 0.5rem;
  font-weight: 700;
  background-color: $sunset;
  border-color: $sunset;
  font-size: 1.15rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.65);

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.35rem;
  }

  @include hover {
    color: #fff;
    background-color: shade-color($sunset, 5%);
    border-color: shade-color($sunset, 5%);
  }

  &:not([tabindex]),
  &:not([href]):not([tabindex]),
  &:not([href]):not([tabindex]):hover {
    // Boostrap override
    color: #fff;
  }
}

.carousel-image-caption-container {
  position: relative;
  margin: 0 auto;
  height: 100%;
  z-index: 1;
}

.carousel-image-caption {
  display: block;
  position: absolute;
  bottom: 7.25rem;
  right: 1.5rem;
  color: #fff;
  font-family: $secondary-font;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;

  span {
    display: inline-block;
    padding: 0.4em 0.65em 0.5em;
    background-color: rgba($charcoal, 0.8);
    line-height: 1em;
    max-height: 32px;
    overflow: hidden;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
  }
}
