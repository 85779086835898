.editable {
  > img,
  p > img {
    width: auto;
    max-width: 100%;
    display: block;
    margin: 1.25rem auto 1.75rem;
    border: none;
  }
}
