.form-group {
  margin-bottom: 1rem;
}

.packages-book-form {
  .toggle-conditional-field {
    display: none;
  }

  h4 {
    margin-top: 1em;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }

  .form-check-input {
    margin-right: 0.5rem;
  }

  p {
    margin-bottom: 0.5rem;
  }
}

.form-check-inline {
  .form-check-input {
    min-width: 1rem;
  }
}

.form-select-white {
  background:
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23FFF' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px;
}

.form-error {
  color: $danger;

  @include text-x-small;

  margin-top: 0.5rem;
}

legend {
  float: none;
}
