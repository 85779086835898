.sidebar {
  position: relative;
}

.sidebar--fixed {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: calc(#{$fixed-header-height} + 2rem);
  }
}
